<template>
  <v-layout :align-baseline="isFullscreen">
    <v-dialog
      v-model="dialog"
      persistent
      :attach="isFullscreen"
      origin="bottom right 60px"
      scrollable
      max-width="1200px"
    >
      <v-card
        class="elevation-0"
        outlined
      >
        <HeaderCard>
          <b>
            <small>
              #{{ audioId }}&nbsp;
              <template v-if="audio && audio.id">
                {{ audio.sessaoDescricao || audio.assunto }} | {{ audio.nucleoNome || audio.oradorNome }}
              </template>

              <template v-else>
                Carregando! Aguarde...
              </template>
            </small>
          </b>
          <v-spacer />
          <v-btn
            icon
            @click="closeView"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </HeaderCard>

        <AudioReviewForm
          :audio="audio"
          :is-loading="isLoading"
        />

        <v-divider v-if="isAdmin" />

        <v-card-actions
          v-if="showControls"
          style="padding-top: 0.7em; padding-bottom: 0.7em;"
        >
          <v-layout
            wrap
            justify-space-around
          >
            <v-btn
              color="blue darken-1"
              text
              @click="closeView"
            >
              Voltar
            </v-btn>

            <v-btn
              outlined
              color="success"
              @click="editAudio"
              v-if="!isLoading"
            >
              Editar<br>Áudio
            </v-btn>

            <v-btn
              outlined
              v-if="isAdmin && !isLoading"
              color="red lighten-2"
              @click="removeAudio"
            >
              Excluir<br>Áudio
            </v-btn>

            <v-btn
              color="orange lighten-2"
              outlined
              @click="setExam"
              v-if="!isExam && !isLoading"
            >
              Colocar<br>em Exame
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

import HeaderCard from '@/components/HeaderCard'
import { formatSecs, getSession } from '@/utils'
import { mapState } from 'vuex'
import AudioReviewForm from './AudioReviewForm'

import {
  ACTION_AUDIO_SAVE,
  ACTION_AUDIO_REMOVE,

  CODE_AUDIOS_EXAM,

  ROUTE_AUDIO_REVIEW,

  STATUS_LOADED,
  ACTION_AUDIO_VIEW
} from '@/constants'

export default {
  components: {
    HeaderCard,
    AudioReviewForm
  },

  props: {
    audioId: {}
  },

  data () {
    return {
      isAdmin: false,
      showControls: false
    }
  },

  computed: {
    isFullscreen () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    },

    isExam () {
      return CODE_AUDIOS_EXAM === this.audio?.revisaoStatus
    },

    dialog () {
      return Boolean(this.audioId)
    },

    ...mapState({
      audio: ({ searchAudios }) => {
        const viewData = { ...searchAudios.viewData }
        if (!viewData || !viewData.id) return {}
        const duracao = formatSecs(viewData.duracao)
        return { ...viewData, duracao }
      },

      isLoading: ({ searchAudios }) => {
        return searchAudios.viewStatus !== STATUS_LOADED
      }
    })
  },

  mounted () {
    this.loadSession()
  },

  methods: {
    loadSession () {
      getSession().then((session) => {
        this.isAdmin = Boolean(session?.administrador)
        this.showControls = Boolean(session?.supervisor || session?.revisor || session?.administrador)
      })
    },

    removeAudio () {
      this.$store.dispatch(ACTION_AUDIO_REMOVE, this.audio.id)
      this.$emit('on-close')
    },

    editAudio () {
      this.$router.push({ name: ROUTE_AUDIO_REVIEW, params: { id: this.audio.id } })
    },

    setExam () {
      this.$store.dispatch(ACTION_AUDIO_SAVE, { ...this.audio, revisaoStatus: CODE_AUDIOS_EXAM })
      this.$emit('on-close')
    },

    closeView () {
      this.$emit('on-close')
    }
  },

  watch: {
    audioId (audioId) {
      if (audioId) this.$store.dispatch(ACTION_AUDIO_VIEW, audioId)
    }
  }
}

</script>
