<template>
  <v-card-text>
    <v-container grid-list-xl>
      <v-layout
        v-if="isLoading"
        wrap
        align-center
        justify-center
      >
        <v-progress-circular
          :size="55"
          indeterminate
          color="primary"
          class="mx-3"
        />
      </v-layout>

      <v-layout
        wrap
        v-else
      >
        <v-flex xs12>
          <FieldStr
            readonly
            label="Assunto"
            v-model="audio.assunto"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <FieldGrau
            readonly
            label="Grau do conteúdo"
            v-model="audio.grau"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <FieldTipo
            readonly
            label="Tipo do Áudio"
            v-model="audio.tipo"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <FieldDate
            readonly
            label="Data"
            v-model="audio.data"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <FieldOrador
            readonly
            label="Orador"
            v-model="audio.orador"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <FieldOrador
            readonly
            label="Dirigente"
            v-model="audio.dirigente"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <FieldOrador
            readonly
            label="Autor"
            v-model="audio.autor"
          />
        </v-flex>

        <v-flex
          xs12
          sm8
        >
          <FieldCore
            readonly
            icon=""
            hide-details
            label="Núcleo"
            v-model="audio.nucleo"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <FieldSessao
            readonly
            label="Sessão"
            v-model="audio.sessao"
          />
        </v-flex>

        <v-flex
          xs12
          sm8
        >
          <FieldWords
            readonly
            label="Palavras Chaves"
            v-model="audio.palavras"
            :rows="1"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <FieldQualidade
            readonly
            label="Qualidade"
            v-model="audio.qualidade"
          />
        </v-flex>

        <v-flex
          xs12
          sm8
        >
          <FieldAlbum
            readonly
            label="Álbum"
            v-model="audio.album"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <FieldPermissao
            readonly
            label="Permissão"
            v-model="audio.permissao"
          />
        </v-flex>

        <v-flex
          xs12
          sm8
        >
          <FieldText
            readonly
            label="Mestre disse"
            v-model="audio.mestreDisse"
            :rows="1"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <FieldText
            readonly
            label="Texto da Revisão"
            v-model="audio.revisaoObs"
            :rows="1"
          />
        </v-flex>

        <v-flex xs12>
          <FieldText
            readonly
            label="Resumo"
            v-model="audio.resumo"
            :rows="1"
          />
        </v-flex>

        <v-flex xs12>
          <FieldText
            readonly
            label="Transcrição"
            v-model="audio.transcricao"
            :rows="1"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>

<script>

import FieldTipo from '@/components/fields/FieldTipo'
import FieldGrau from '@/components/fields/FieldGrau'
import FieldCore from '@/components/fields/FieldCore'
import FieldStr from '@/components/fields/FieldStr'
import FieldText from '@/components/fields/FieldText'
import FieldDate from '@/components/fields/FieldDate'
import FieldWords from '@/components/fields/FieldWords'
import FieldAlbum from '@/components/fields/FieldAlbum'
import FieldSessao from '@/components/fields/FieldSessao'
import FieldOrador from '@/components/fields/FieldOrador'
import FieldQualidade from '@/components/fields/FieldQualidade'
import FieldPermissao from '@/components/fields/FieldPermissao'

export default {
  components: {
    FieldStr,
    FieldDate,
    FieldGrau,
    FieldText,
    FieldTipo,
    FieldCore,
    FieldWords,
    FieldAlbum,
    FieldSessao,
    FieldOrador,
    FieldPermissao,
    FieldQualidade
  },

  props: {
    audio: {},
    isLoading: {}
  }
}

</script>
