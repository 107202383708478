var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',{attrs:{"items":[
    { value: 35, text: 'Quadro de Mestres' },
    { value: 33, text: 'Sessão da Direção' },
    { value: 32, text: 'Sessão Instrutiva' },
    { value: 34, text: 'Caráter Instrutivo' },
    { value: 20, text: 'Sessão da 1ª Escala' },
    { value: 21, text: 'Sessão da 2ª Escala' },
    { value: 22, text: 'Escala Anual Natal' },
    { value: 23, text: 'Escala Anual de Reis' },
    { value: 24, text: 'Escala Anual Aniversário do Mestre' },
    { value: 25, text: 'Escala Anual Ressurreição do Mestre' },
    { value: 26, text: 'Escala Anual São João' },
    { value: 27, text: 'Escala Anual Aniversário da União' },
    { value: 28, text: 'Escala Anual Confirmação da União no Astral supeior' },
    { value: 30, text: 'Sessão extra Ano Novo' },
    { value: 31, text: 'Sessão extra de São Cosmo e São Damião' },
    { value: 29, text: 'Sessão extra' },
    { value: 36, text: 'Sessão de Casal' },
    { value: 37, text: 'Sessão de Jovens' },
    { value: 38, text: 'Distribuição de Vegetal em Preparo' },
    { value: 39, text: 'Sessão de Adventício' }
  ],"rules":_vm.rules,"label":_vm.i18n(_vm.label),"outlined":_vm.outlined,"autofocus":_vm.autofocus,"clearable":_vm.clearable,"autocomplete":"off","dense":"","hide-details":""},model:{value:(_vm.sessao),callback:function ($$v) {_vm.sessao=$$v},expression:"sessao"}})
}
var staticRenderFns = []

export { render, staticRenderFns }