<template>
  <v-autocomplete
    :items="[
      { value: 35, text: 'Quadro de Mestres' },
      { value: 33, text: 'Sessão da Direção' },
      { value: 32, text: 'Sessão Instrutiva' },
      { value: 34, text: 'Caráter Instrutivo' },
      { value: 20, text: 'Sessão da 1ª Escala' },
      { value: 21, text: 'Sessão da 2ª Escala' },
      { value: 22, text: 'Escala Anual Natal' },
      { value: 23, text: 'Escala Anual de Reis' },
      { value: 24, text: 'Escala Anual Aniversário do Mestre' },
      { value: 25, text: 'Escala Anual Ressurreição do Mestre' },
      { value: 26, text: 'Escala Anual São João' },
      { value: 27, text: 'Escala Anual Aniversário da União' },
      { value: 28, text: 'Escala Anual Confirmação da União no Astral supeior' },
      { value: 30, text: 'Sessão extra Ano Novo' },
      { value: 31, text: 'Sessão extra de São Cosmo e São Damião' },
      { value: 29, text: 'Sessão extra' },
      { value: 36, text: 'Sessão de Casal' },
      { value: 37, text: 'Sessão de Jovens' },
      { value: 38, text: 'Distribuição de Vegetal em Preparo' },
      { value: 39, text: 'Sessão de Adventício' }
    ]"
    :rules="rules"
    :label="i18n(label)"
    :outlined="outlined"
    :autofocus="autofocus"
    :clearable="clearable"
    autocomplete="off"
    dense
    hide-details

    v-model="sessao"
  />
</template>

<script>

export default {
  props: {
    value: {},
    rules: {},
    label: { default: 'filter.sessao' },
    outlined: { default: false },
    clearable: { default: true },
    autofocus: { default: false }
  },

  data () {
    return {
      sessao: this.value
    }
  },

  watch: {
    sessao () {
      const changed = this.sessao !== this.value
      if (changed) this.$emit('input', this.sessao)
    },

    value () {
      const changed = this.sessao !== this.value
      if (changed) this.sessao = this.value
    }
  }
}
</script>
