var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',{attrs:{"items":[
    { value: 3, text: 'Chamadas' },
    { value: 7, text: 'Palavra do Mestre' },
    { value: 1, text: 'Oratórias' },
    { value: 2, text: 'Histórias' },
    { value: 4, text: 'Sessão' },
    { value: 5, text: 'Músicas' },
    { value: 6, text: 'Explicação' }
  ],"rules":_vm.rules,"label":_vm.i18n(_vm.label),"outlined":_vm.outlined,"autofocus":_vm.autofocus,"clearable":_vm.clearable,"autocomplete":"off","dense":"","hide-details":""},model:{value:(_vm.tipo),callback:function ($$v) {_vm.tipo=$$v},expression:"tipo"}})
}
var staticRenderFns = []

export { render, staticRenderFns }