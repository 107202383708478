<template>
  <v-autocomplete
    dense
    :items="graus"
    :label="i18n(label)"
    :outlined="outlined"
    :autofocus="autofocus"
    :clearable="true"
    class="pt-0"
    :prepend-inner-icon="icon"
    :hide-details="hideDetails"
    v-model="grau"
  />
</template>

<script>
const onlyNumber = (str) => isNaN(str) ? null : Number(str)
export default {
  props: {
    icon: {},
    value: {},
    label: { default: 'field.grau' },
    outlined: { default: false },
    autofocus: {},
    hideDetails: { default: true }
  },

  data () {
    return {
      grau: onlyNumber(this.value),
      graus: [
        { value: 1, text: 'QS' },
        { value: 2, text: 'CI' },
        { value: 3, text: 'CDC' },
        { value: 4, text: 'QM' }
      ]
    }
  },

  watch: {
    grau () {
      const grau = onlyNumber(this.grau)
      const changed = grau !== onlyNumber(this.value)
      if (changed) this.$emit('input', grau)
    },

    value () {
      const value = onlyNumber(this.value)
      const changed = value !== onlyNumber(this.grau)
      if (changed) this.grau = value
    }
  }
}
</script>
