<template>
  <v-autocomplete
    dense
    :items="nucleos"
    :label="i18n(label)"
    :loading="loading"
    :outlined="outlined"
    :autofocus="autofocus"
    :filter="() => true"
    :clearable="true"
    :search-input.sync="search"
    :hide-details="hideDetails"
    class="pt-0"
    :no-data-text="'filter.sync'.translate()"
    v-model="nucleo"
    @keyup.enter="$emit('on-enter', $event)"
  />
</template>

<script>
import { requests } from '@/plugins/Amplify'
const onlyNumber = (str) => isNaN(str) ? null : Number(str)
const onlyString = (str) => String(str || '').trim()

export default {
  props: {
    value: {},
    label: { default: 'filter.nucleo' },
    outlined: { default: false },
    autofocus: { default: false },
    hideDetails: { default: true }
  },

  data () {
    return {
      nucleos: [],
      search: null,
      loading: false,
      timeout: null,
      nucleo: onlyNumber(this.value)
    }
  },

  methods: {
    clearTimeout () {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  },

  mounted () {
    if (this.value) {
      requests.getCores({ id: this.value }).then((data) => {
        if (!data.length) return
        this.nucleos = data.map(({ id, nucleo, regiao }) => ({ value: id, text: `${nucleo} - ${regiao}` }))
      })
    }
  },

  watch: {
    nucleo () {
      const nucleo = onlyNumber(this.nucleo)
      const changed = nucleo !== onlyNumber(this.value)
      if (changed) this.$emit('input', nucleo)
    },

    value () {
      const value = onlyNumber(this.value)
      const changed = value !== onlyNumber(this.nucleo)
      if (changed) this.nucleo = value
    },

    search () {
      this.clearTimeout()

      const nome = onlyString(this.search)
      if (!nome || this.nucleos.find(({ text }) => text === nome)) {
        this.loading = false
        return
      }

      this.loading = true

      this.timeout = setTimeout(async () => {
        try {
          const data = await requests.getCores({ nome })
          this.nucleos = data.map(({ id, nucleo, regiao }) => ({ value: id, text: `${nucleo} - ${regiao}` }))
        } catch (error) {
          console.error(error)
        }

        this.loading = false
      }, 275)
    }
  }
}
</script>
