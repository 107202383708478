<template>
  <div>
    <v-btn
      v-if="userReview"
      x-small
      depressed
      color="teal darken-3"
      dark
      :style="userDegree ? 'height: 36px' : ''"
    >
      <span>
        Em revisão
        <br v-if="userDegree">
        <small>{{ `${userDegree} - ${userName.split(' ').shift()} ${userName.split(' ').pop()}` }}</small>
      </span>
    </v-btn>

    <v-btn
      v-if="!userReview && mustReview"
      x-small
      outlined
      depressed
      :color="color"
      :to="urlReview"
    >
      Revisar
    </v-btn>

    <v-btn
      v-if="!userReview && reviewStatus === 2"
      x-small
      outlined
      depressed
      :color="color"
      :style="reviewDegree ? 'height: 36px' : ''"
      :to="urlReview"
    >
      Aprovar
    </v-btn>

    <v-btn
      v-if="!userReview && reviewStatus === 3"
      x-small
      outlined
      depressed
      :color="color"
      :style="reviewDegree ? 'height: 36px' : ''"
      :to="urlReview"
    >
      Examinar
    </v-btn>
  </div>
</template>

<script>
import { ROUTE_AUDIO_REVIEW } from '@/constants'

export default {
  props: [
    'id',
    'color',

    'userName',
    'userDegree',
    'userReview',

    'reviewStatus',
    'reviewDegree',
    'reviewUser'
  ],

  methods: {
    onOpenList (e) {
      e.preventDefault()
      this.$emit('on-open-list')
    }
  },

  computed: {
    urlReview () {
      return { name: ROUTE_AUDIO_REVIEW, params: { id: this.id } }
    },

    mustReview () {
      return [0, 4].includes(Number(this.reviewStatus))
    }
  }
}
</script>
