<template>
  <v-autocomplete
    :items="[
      { value: 3, text: 'Chamadas' },
      { value: 7, text: 'Palavra do Mestre' },
      { value: 1, text: 'Oratórias' },
      { value: 2, text: 'Histórias' },
      { value: 4, text: 'Sessão' },
      { value: 5, text: 'Músicas' },
      { value: 6, text: 'Explicação' }
    ]"
    :rules="rules"
    :label="i18n(label)"
    :outlined="outlined"
    :autofocus="autofocus"
    :clearable="clearable"
    autocomplete="off"
    dense
    hide-details
    v-model="tipo"
  />
</template>

<script>

export default {
  props: {
    value: {},
    rules: {},
    label: { default: 'filter.tipo' },
    outlined: { default: false },
    clearable: { default: true },
    autofocus: { default: false }
  },

  data () {
    return {
      tipo: this.value
    }
  },

  watch: {
    tipo () {
      const changed = this.tipo !== this.value
      if (changed) this.$emit('input', this.tipo)
    },

    value () {
      const changed = this.tipo !== this.value
      if (changed) this.tipo = this.value
    }
  }
}
</script>
