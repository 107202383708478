<template>
  <v-autocomplete
    dense
    :items="revisores"
    :label="i18n(label)"
    :loading="loading"
    :outlined="outlined"
    :autofocus="autofocus"
    :filter="() => true"
    :clearable="true"
    :search-input.sync="search"
    :hide-details="hideDetails"
    class="pt-0"
    :no-data-text="'filter.sync'.translate()"
    v-model="revisor"
    @keyup.enter="$emit('on-enter', $event)"
  />
</template>

<script>
import { requests } from '@/plugins/Amplify'
const onlyNumber = (str) => isNaN(str) ? null : Number(str)
const onlyString = (str) => String(str || '').trim()

export default {
  props: {
    value: {},
    label: { default: 'filter.revisor' },
    outlined: { default: false },
    autofocus: { default: false },
    hideDetails: { default: true }
  },

  data () {
    return {
      revisores: [],
      search: null,
      loading: false,
      timeout: null,
      revisor: onlyNumber(this.value)
    }
  },

  methods: {
    clearTimeout () {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  },

  mounted () {
    if (this.value) {
      requests.getUsers({ id: this.value }).then(({ rows }) => {
        this.revisores = mapUsers(rows)
      })
    }
  },

  watch: {
    revisor () {
      const revisor = onlyNumber(this.revisor)
      const changed = revisor !== onlyNumber(this.value)
      if (changed) this.$emit('input', revisor)
    },

    value () {
      const value = onlyNumber(this.value)
      const changed = value !== onlyNumber(this.revisor)
      if (changed) this.revisor = value
    },

    search () {
      this.clearTimeout()

      const nome = onlyString(this.search)
      if (!nome || this.revisores.find(({ text }) => text === nome)) {
        this.loading = false
        return
      }

      this.loading = true

      this.timeout = setTimeout(async () => {
        try {
          const { rows } = await requests.getUsers({ nome })
          this.revisores = mapUsers(rows)
        } catch (error) {
          console.error(error)
        }

        this.loading = false
      }, 275)
    }
  }
}

const mapUsers = (rows) => rows.map(({ reuni_id: id, name, grau }) => ({ value: id, text: `${grau.replace('CC', 'CDC')} - ${name}` }))
</script>
