<template>
  <v-text-field
    dense
    :label="i18n(label)"
    :outlined="outlined"
    :autofocus="autofocus"
    :hide-details="hideDetails"
    class="pt-0"
    type="tel"
    v-model="date"
    v-mask="{ mask: mask, masked: true, tokens: tokens }"
  />
</template>

<script>
import { mask } from 'vue-the-mask'

const transform = (vm) => (num) => {
  const date = String(vm.date || '').replace(/[^\d?]/g, '')
  const day = `${date[0] || ''}${date[1] || ''}`
  const month = `${date[2] || ''}${date[3] || ''}`
  const year = `${date[4] || ''}${date[5] || ''}${date[6] || ''}${date[7] || ''}`

  if (day && !isNaN(day)) {
    if (Number(day) > 31) return ''
    if (Number(day) <= 0 && day.length > 1) return ''
  }

  if (month && !isNaN(month)) {
    if (Number(month) > 12) return ''
    if (Number(month) <= 0 && month.length > 1) return ''
  }

  if (!isNaN(year)) {
    if (Number(year) <= 1957 && year.length === 4) return ''

    const currDate = new Date()
    if (Number(year) > currDate.getFullYear()) return ''

    const currYear = Number(year) === currDate.getFullYear()
    const greaterMonth = !isNaN(month) && Number(month) > (currDate.getMonth() + 1)
    if (currYear && greaterMonth) return ''

    const currMonth = !isNaN(month) && Number(month) === (currDate.getMonth() + 1)
    const greaterDay = !isNaN(day) && Number(day) === currDate.getDate()
    if (currYear && currMonth && greaterDay) return ''
  }

  return num
}

export default {
  directives: {
    mask
  },

  props: {
    value: {},
    label: { default: 'filter.data' },
    outlined: { default: false },
    autofocus: {},
    hideDetails: { default: true }
  },

  data () {
    const vm = this

    return {
      date: String(this.value || '').split('-').reverse().join('/'),
      mask: 'DD/MM/AAAA',
      tokens: {
        D: { pattern: /[0-9?]/, transform: transform(vm) },
        M: { pattern: /[0-9?]/, transform: transform(vm) },
        A: { pattern: /[0-9?]/, transform: transform(vm) }
      }
    }
  },

  watch: {
    value () {
      const date = String(this.value || '').split('-').reverse().join('/')
      if (date !== this.date) this.date = date
    },

    date () {
      const value = String(this.date || '').split('/').reverse().join('-')
      if (value !== this.value) this.$emit('input', value)
    }
  }
}
</script>
