<template>
  <v-card
    class="elevation-0"
    outlined
  >
    <HeaderCard>
      <v-icon
        class="mr-1"
        size="medium"
      >
        {{ icon }}
      </v-icon>
      <b>{{ title.translate() }} {{ label.translate() }}</b>

      <v-spacer />

      <v-menu
        bottom
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <v-btn
            m
            dark
            icon
            v-on="on"
          >
            <v-icon small>
              mdi-filter-plus-outline
            </v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item dense>
            <v-list-item-action>
              <v-checkbox
                v-model="showFilters.tipo"
                color="primary"
                label="Tipo"
                :ripple="false"
              />
            </v-list-item-action>
          </v-list-item>

          <v-list-item dense>
            <v-list-item-action>
              <v-checkbox
                v-model="showFilters.orador"
                color="primary"
                label="Orador"
                :ripple="false"
              />
            </v-list-item-action>
          </v-list-item>

          <v-list-item dense>
            <v-list-item-action>
              <v-checkbox
                v-model="showFilters.data"
                color="primary"
                label="Data"
                :ripple="false"
              />
            </v-list-item-action>
          </v-list-item>

          <v-list-item dense>
            <v-list-item-action>
              <v-checkbox
                v-model="showFilters.dirigente"
                color="primary"
                label="Dirigente"
                :ripple="false"
              />
            </v-list-item-action>
          </v-list-item>

          <v-list-item dense>
            <v-list-item-action>
              <v-checkbox
                v-model="showFilters.transcricao"
                color="primary"
                label="Transcrição"
                :ripple="false"
              />
            </v-list-item-action>
          </v-list-item>

          <v-list-item dense>
            <v-list-item-action>
              <v-checkbox
                v-model="showFilters.mestreDisse"
                color="primary"
                label="Mestre Disse"
                :ripple="false"
              />
            </v-list-item-action>
          </v-list-item>

          <v-list-item dense>
            <v-list-item-action>
              <v-checkbox
                v-model="showFilters.grau"
                color="primary"
                label="Grau"
                :ripple="false"
              />
            </v-list-item-action>
          </v-list-item>

          <v-list-item dense>
            <v-list-item-action>
              <v-checkbox
                v-model="showFilters.sessao"
                color="primary"
                label="Sessão"
                :ripple="false"
              />
            </v-list-item-action>
          </v-list-item>

          <v-list-item dense>
            <v-list-item-action>
              <v-checkbox
                v-model="showFilters.nucleo"
                color="primary"
                label="Núcleo"
                :ripple="false"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </HeaderCard>

    <v-card-text style="padding: 0;">
      <v-form
        ref="form"
        autocomplete="off"
      >
        <v-container
          grid-list-xl
          fluid
        >
          <v-layout
            wrap
            row
            align-center
          >
            <v-flex
              xs12
              sm4
            >
              <FilterText
                label="Assunto/Resumo/Orador/CDs/Palavras-Chaves"
                v-model="filters.assunto"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="showFilters.tipo"
            >
              <FilterTipo v-model="filters.tipo" />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="showFilters.orador"
            >
              <FilterOrador
                label="Orador"
                v-model="filters.orador"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="showFilters.dirigente"
            >
              <FilterOrador
                label="Dirigente"
                v-model="filters.dirigente"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="showFilters.data"
            >
              <FilterDatas v-model="filters.data" />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="showFilters.transcricao"
            >
              <FilterText
                label="Transcrição"
                v-model="filters.transcricao"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="showFilters.mestreDisse"
            >
              <FilterText
                label="Mestre Disse"
                v-model="filters.mestreDisse"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="showFilters.grau"
            >
              <FilterGrau v-model="filters.grau" />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="showFilters.sessao"
            >
              <FilterSessao v-model="filters.sessao" />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="showFilters.nucleo"
            >
              <FilterNucleo v-model="filters.nucleo" />
            </v-flex>

            <v-flex
              v-if="showRevisor"
              xs12
              sm4
            >
              <FilterRevisor v-model="filters.revisaoRevisor" />
            </v-flex>

            <v-flex
              v-if="count"
              xs12
              class="mr-0 mt-0 mb-0 pr-0 pt-0 pb-2 pl-2"
            >
              <v-chip
                small
                color="primary"
                class="ma-1 pr-2"
                label
                outlined
              >
                {{ count === 1 ? 'um áudio encontrado' : '' }}{{ count > 1 ? `${count} áudios encontrados` : '' }}
              </v-chip>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>

      <v-data-table
        :headers="headers"
        :items="rows"
        :loading="isLoading"

        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"

        disable-pagination
        hide-default-footer

        class="elevation-0"
        item-key="idStatus"
      >
        <template #item="{ item, index }">
          <AudioSearchItem
            :id="item.id"
            :data="item.data"
            :color="color"
            :album="item.albumNome"
            :dirigente="item.dirigenteNome"
            :sessao-id="item.sessao"
            :sessao="item.sessaoDescricao"
            :assunto="item.assunto"
            :nucleo="item.nucleoNome"
            :orador="item.oradorNome"
            :duracao="item.duracao"
            :aprovado="item.revisaoStatus"
            :revisao="reviews[item.id]"
            :revisor-grau="String(item.revisorGrau || '')"
            :revisor-nome="String(item.revisorNome || '')"
            :show-revisor="showRevisor"
            :odd="index % 2"

            @on-view="audioViewId = item.id"
            @on-open-list="listAudios(item)"
            @on-listen-audio="listenAudio(item)"
          />
        </template>

        <template #footer>
          <v-divider class="my-2" />

          <v-pagination
            v-model="page"
            :length="Math.ceil(count / 12)"
            :total-visible="viewPhone ? 7 : 9"
            v-if="Math.ceil(count / 12) > 1"
            @input="$vuetify.goTo(0, { duration: 850, offset: 0, easing: 'easeInOutCubic' })"
          />
        </template>
      </v-data-table>

      <AudioView
        :audio-id="audioViewId"
        @on-close="audioViewId = 0"
      />

      <AudioList
        :id="selected.id"
        :data="selected.data"
        :sessao="selected.sessao"
        :sessao-descricao="selected.sessaoDescricao"
        :nucleo="selected.nucleo"
        :album-id="selected.albumId"
        :album-nome="selected.albumNome"
        :nucleo-nome="selected.nucleoNome"
        :dirigente="selected.dirigente"
        :dirigente-nome="selected.dirigenteNome"
        @on-close="closeAudioList"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import FilterGrau from '@/components/filters/FilterGrau'
import FilterTipo from '@/components/filters/FilterTipo'
import FilterText from '@/components/filters/FilterText'
import FilterDatas from '@/components/filters/FilterDatas'
import FilterNucleo from '@/components/filters/FilterNucleo'
import FilterOrador from '@/components/filters/FilterOrador'
import FilterRevisor from '@/components/filters/FilterRevisor'
import FilterSessao from '@/components/filters/FilterSessao'

import AudioView from './AudioView'
import AudioList from './AudioList'
import HeaderCard from '@/components/HeaderCard'
import AudioSearchItem from './AudioSearchItem'
import { mapState } from 'vuex'

import {
  STATUS_LOADING,
  ACTION_AUDIO_LISTEN,
  ACTION_AUDIO_SEARCH,
  ROUTE_AUDIOS_APPROVED
} from '@/constants'

const hasQryVal = (qry, val) => qry[val] !== undefined && String(qry[val]).trim()
const jsonQry = (qry) => JSON.stringify(Object.keys(qry).sort().reduce((obj, attr) => Object.assign(obj, { [attr]: [null, undefined].includes(qry[attr]) ? '' : String(qry[attr]).trim() }), {}))

const makeFilters = (qry, breakpoint) => {
  const sortBy = hasQryVal(qry, 'sortBy') ? qry.sortBy : 'data'
  const orderDesc = String(qry.order || '').trim() === 'DESC'

  const showFilters = {
    tipo: !breakpoint.xs,
    orador: !breakpoint.xs,

    data: hasQryVal(qry, 'data'),
    assunto: hasQryVal(qry, 'assunto'),

    grau: !isNaN(qry.grau),
    nucleo: !isNaN(qry.nucleo),
    sessao: !isNaN(qry.sessao),
    dirigente: !isNaN(qry.dirigente),
    revisaoRevisor: !isNaN(qry.revisaoRevisor)
  }

  const filters = {}
  if (qry.tipo && !isNaN(qry.tipo)) filters.tipo = Number(qry.tipo)
  if (qry.orador && !isNaN(qry.orador)) filters.orador = Number(qry.orador)
  if (showFilters.data) filters.data = qry.data
  if (showFilters.grau) filters.grau = Number(qry.grau)
  if (showFilters.nucleo) filters.nucleo = qry.nucleo
  if (showFilters.sessao) filters.sessao = Number(qry.sessao)
  if (showFilters.assunto) filters.assunto = qry.assunto
  if (showFilters.dirigente) filters.dirigente = Number(qry.dirigente)
  if (showFilters.transcricao) filters.transcricao = qry.transcricao
  if (showFilters.mestreDisse) filters.mestreDisse = Number(qry.mestreDisse)
  if (showFilters.revisaoRevisor) filters.revisaoRevisor = Number(qry.revisaoRevisor)

  const options = {
    sortBy: [sortBy],
    sortDesc: [orderDesc],
    itemsPerPage: 12
  }

  return { options, filters, showFilters }
}

export default {
  components: {
    AudioList,
    AudioView,
    AudioSearchItem,
    HeaderCard,
    FilterTipo,
    FilterGrau,
    FilterText,
    FilterDatas,
    FilterNucleo,
    FilterOrador,
    FilterSessao,
    FilterRevisor
  },

  props: {
    icon: { default: 'mdi-cloud-search-outline' },
    title: { default: 'title.searchAudios' },
    label: { default: '' },
    color: { default: '' },
    route: { default: ROUTE_AUDIOS_APPROVED },
    status: { default: null },
    showRevisor: { default: true }
  },

  data () {
    const qry = { ...this.$route.query }
    const page = !qry.pag || isNaN(qry.page) ? 1 : Number(qry.page)

    return {
      page,
      selected: {},
      audioViewId: 0,
      ...makeFilters(qry, this.$vuetify.breakpoint)
    }
  },

  computed: {
    ...mapState({
      rows: ({ searchAudios }) => searchAudios.rows,
      count: ({ searchAudios }) => searchAudios.count,
      reviews: ({ searchAudios }) => searchAudios.reviews,
      isLoading: ({ searchAudios }) => searchAudios.status === STATUS_LOADING
    }),

    headers () {
      const headers = []
      headers.push({ text: '', align: 'center', sortable: false, value: 'play' })
      headers.push({ text: 'Assunto', align: 'start', sortable: true, value: 'assunto' })
      if (!this.viewPhone) headers.push({ text: 'Núcleo', align: 'start', sortable: true, value: 'nucleo' })
      if (!this.viewPhone) headers.push({ text: 'Sessão', align: 'start', sortable: true, value: 'data' })
      if (!this.viewPhone && this.showRevisor) headers.push({ text: 'Revisão', align: 'center', sortable: false, value: 'revisao' })
      return headers
    },

    viewPhone () {
      return this.$vuetify.breakpoint.xs
    }
  },

  methods: {
    listAudios (audio) {
      this.selected = audio
      this.listenAudio(audio)
    },

    listenAudio (audio) {
      this.$store.dispatch(ACTION_AUDIO_LISTEN, audio)
    },

    searchAudios () {
      const query = {
        page: String(this.page),
        order: this.options.sortDesc[0] !== false ? 'DESC' : 'ASC',
        sortBy: this.options.sortBy.length ? this.options.sortBy[0] : 'data'
      }

      const fltrs = { ...this.filters }
      if (hasQryVal(fltrs, 'data')) query.data = fltrs.data
      if (hasQryVal(fltrs, 'tipo')) query.tipo = String(fltrs.tipo)
      if (hasQryVal(fltrs, 'grau')) query.grau = String(fltrs.grau)
      if (hasQryVal(fltrs, 'orador')) query.orador = String(fltrs.orador)
      if (hasQryVal(fltrs, 'nucleo')) query.nucleo = fltrs.nucleo
      if (hasQryVal(fltrs, 'sessao')) query.sessao = String(fltrs.sessao)
      if (hasQryVal(fltrs, 'assunto')) query.assunto = fltrs.assunto
      if (hasQryVal(fltrs, 'dirigente')) query.dirigente = fltrs.dirigente
      if (hasQryVal(fltrs, 'transcricao')) query.transcricao = fltrs.transcricao
      if (hasQryVal(fltrs, 'mestreDisse')) query.mestreDisse = fltrs.mestreDisse

      if (this.showRevisor && hasQryVal(fltrs, 'revisaoRevisor')) query.revisaoRevisor = fltrs.revisaoRevisor

      delete query.revisaoStatus
      if (this.status) query.revisaoStatus = this.status

      Object.keys(query).forEach((key) => {
        if (['null', null, undefined, ''].includes(query[key])) delete query[key]
      })

      this.$store.dispatch(ACTION_AUDIO_SEARCH, query)
      if (jsonQry(this.$router.history.current.query) === jsonQry(query)) return
      this.$router.push({ name: this.route, query })
    },

    closeAudioList () {
      this.selected = {}
    }
  },

  watch: {
    page () {
      this.searchAudios()
    },

    audioViewId (audioViewId) {
      if (audioViewId) return
      setTimeout(() => this.searchAudios(), 500)
    },

    filters: {
      deep: true,
      handler () {
        this.page = 1
        this.searchAudios()
      }
    },

    options: {
      deep: true,
      handler () {
        this.searchAudios()
      }
    }
  }
}
</script>
