<template>
  <AudioSearch
    :icon="icon"
    :label="label"
    :title="title"
    :color="color"
    :route="route"
    :status="status"
    :show-revisor="true"
  />
</template>

<script>
import menuConfig from '@/components/menuConfig'
import AudioSearch from '@/views/Dashboard/AudioSearch'
import {
  ROUTE_AUDIOS_REVIEW,
  CODE_AUDIOS_WITHOUT_REVIEW
} from '@/constants'

export default {
  components: {
    AudioSearch
  },

  data () {
    const { icon, title, label, route, color } = menuConfig.find(({ route }) => route === ROUTE_AUDIOS_REVIEW)
    return { icon, title, label, route, color, status: `${CODE_AUDIOS_WITHOUT_REVIEW}` }
  }
}
</script>
