<template>
  <v-layout :align-baseline="isFullscreen">
    <v-dialog
      v-model="dialog"
      persistent
      :attach="isFullscreen"
      origin="bottom right 60px"
      scrollable
      max-width="550px"
    >
      <v-card
        class="elevation-0"
        outlined
        :loading="isLoading"
      >
        <HeaderCard>
          <b>
            <small>{{ albumNome || sessaoDescricao }}</small>
            <span v-if="!albumNome && nucleoNome && sessaoDescricao">&nbsp;|&nbsp;</span>
            <small>{{ !albumNome && nucleoNome || '' }}</small>
          </b>
          <v-spacer />
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </HeaderCard>

        <v-divider />

        <v-card-text :style="`padding: 0; ${isFullscreen ? `padding-bottom: ${marginFooter}px` : 'height: 1200px; max-height: 65vh'}`">
          <v-list
            dense
            two-line
          >
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="albumNome">{{ rows.length }} áudios</span>
                  <span v-if="!albumNome && (data || dirigenteNome)">{{ dateFormated }} {{ dirigenteNome }}</span>
                </v-list-item-title>
              </v-list-item-content>

              <small>
                <v-list-item-avatar>
                  <v-checkbox
                    v-model="listenAll"
                    label="Ouvir todas"
                    :ripple="false"
                    bottom
                  />
                </v-list-item-avatar>
              </small>
            </v-list-item>

            <v-list-item-group
              v-model="indexItem"
              color="primary"
              no-action
            >
              <template v-for="(audio, index) in rows">
                <AudioListItem
                  :key="index"
                  :id="audio.id"
                  :orador="audio.orador"
                  :assunto="audio.assunto"
                  :duracao="audio.duracao"
                  :audio-type="audio.arquivoType"
                  :classes="indexItem === index ? '' : (index % 2 ? 'odd' : 'even')"
                />
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import HeaderCard from '@/components/HeaderCard'
import AudioListItem from './AudioListItem'
import { mapState } from 'vuex'

import {
  STATUS_LOADING,
  ACTION_AUDIO_LIST,
  ACTION_AUDIO_LISTEN,
  ACTION_AUDIO_LIST_CLEAR
} from '@/constants'

export default {
  components: {
    HeaderCard,
    AudioListItem
  },

  props: [
    'id',
    'data',
    'nucleo',
    'nucleoNome',
    'sessao',
    'sessaoDescricao',
    'dirigente',
    'dirigenteNome',
    'albumId',
    'albumNome'
  ],

  data () {
    return {
      dialog: false,
      indexItem: -1,
      listenAll: false,
      marginFooter: 0
    }
  },

  computed: {
    isFullscreen () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    },

    dateFormated () {
      return this.data
        ? this.data.split('-').reverse().join('/')
        : ''
    },

    ...mapState({
      rows: ({ playlistAudios }) => playlistAudios.rows,
      isLoading: ({ playlistAudios }) => playlistAudios.status === STATUS_LOADING,

      stream ({ searchAudios: { stream } }) {
        return stream
      }
    })
  },

  methods: {
    clearAudios () {
      this.$store.dispatch(ACTION_AUDIO_LIST_CLEAR)
    },

    listAudios () {
      const params = {
        sortBy: 'id',
        order: 'ASC',
        limit: 9999999
      }

      if (this.albumId) {
        params.album = this.albumId
      } else {
        if (this.data) params.data = this.data
        if (this.nucleo) params.nucleo = this.nucleo
        if (this.sessao) params.sessao = this.sessao
        if (this.dirigente) params.dirigente = this.dirigente
      }

      this.$store.dispatch(ACTION_AUDIO_LIST, params)
    }
  },

  watch: {
    id () {
      this.dialog = Boolean(this.id)
    },

    rows (rows) {
      if (!rows || !rows.length) return

      setTimeout(() => {
        const { footer } = this.$parent.$parent.$parent.$parent.$parent.$refs
        if (footer) this.marginFooter = footer.$el.offsetHeight
      }, 1000)
    },

    dialog (dialog) {
      if (dialog) return this.listAudios()
      this.clearAudios()
      this.$emit('on-close')
    },

    'stream.action' (action) {
      if (this.isLoading) return

      const streamId = Number(this.stream.id)
      const indexItem = this.rows.findIndex(({ id }) => Number(id) === streamId)
      if (indexItem !== this.indexItem) this.indexItem = indexItem
      if (!this.listenAll) return
      if (action !== 'ENDED') return

      const nextAudio = indexItem + 1
      if (nextAudio >= this.rows.length) return
      this.$store.dispatch(ACTION_AUDIO_LISTEN, this.rows[nextAudio])
    }
  }
}
</script>
